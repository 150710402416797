/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

'use client';

import React, {useEffect} from 'react';
import {useRouter} from 'next/navigation';
import {signOut, useSession} from 'next-auth/react';
import {
  AppBar,
  Box,
  Toolbar,
  Tooltip,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import {AccountCircle, ArrowDropDown} from '@mui/icons-material';
import useWindowSize from 'hooks/getWindowSize';
import {LogoutButton, ReportsButton} from './NavButtons';

function Nav() {
  const router = useRouter();
  const {data: session, update} = useSession();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const [screenWidth] = useWindowSize();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleReportsButton = () => {
    router.replace(`/reports?tab=unresolved`);
    router.refresh();
  };

  const handleLogout = () => {
    signOut({redirect: false}).then(() => router.push('/'));
  };

  useEffect(() => {
    update();
  }, []);

  return (
    <AppBar
      id="original-appbar"
      elevation={0}
      position="fixed"
      component="nav"
      color="default"
      sx={{
        backgroundColor: 'grays.dark',
        display: 'flex',
        my: '24px',
        boxShadow: '0px 10px 15px -5px rgba(255, 255, 255, 1)',
        width: 1,
        top: 0,
        left: 0,
      }}
    >
      {session &&
        // @ts-ignore
        session?.error !== 'RefreshAccessTokenError' &&
        // @ts-ignore
        session?.error !== 'NotAuthorized' && (
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pl: 4,
              margin: 'auto',
            }}
          >
            <Box
              sx={{
                mx: '32px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 0.8 * screenWidth,
              }}
              gridColumn="span 6"
            >
              <Box
                component="img"
                sx={{
                  cursor: 'pointer',
                  justifyContent: 'flex-start',
                  height: 40,
                  maxHeight: 200,
                  maxWidth: 300,
                }}
                gridColumn="span 2"
                alt="DELVE Logo"
                src="/logo.svg"
                onClick={() => router.push('/')}
              />
              <Box
                sx={{
                  pr: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
                gridColumn="span 2"
              >
                <ReportsButton handleClick={handleReportsButton} />
                <Tooltip title="Open settings">
                  <Button
                    onClick={handleOpenUserMenu}
                    sx={{
                      p: 0,
                      textTransform: 'uppercase',
                      color: 'black',
                    }}
                  >
                    <AccountCircle sx={{color: 'divider'}} />
                    <ArrowDropDown sx={{color: 'divider'}} />
                  </Button>
                </Tooltip>
                <Menu
                  sx={{
                    mt: '48px',
                    '&.MuiMenu-root .MuiMenu-paper': {
                      backgroundColor: 'grays.dark',
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box>
                    <MenuItem onClick={handleCloseUserMenu}>
                      <LogoutButton handleClick={handleLogout} />
                    </MenuItem>
                  </Box>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        )}
    </AppBar>
  );
}

export default Nav;
