/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

'use client';

import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useMemo,
} from 'react';
import {Params, RESET_PARAMS} from 'types/reports';

interface ConfirmedParamsContextType {
  confirmedParams: Params;
  setConfirmedParams: React.Dispatch<React.SetStateAction<Params>>;
}

const ConfirmedParamsContext = createContext<
  ConfirmedParamsContextType | undefined
>(undefined);

export const useConfirmedParamsContext = () => {
  const context = useContext(ConfirmedParamsContext);
  if (!context) {
    throw new Error(
      'useConfirmedParamsContext must be used within a ConfirmedParamsProvider',
    );
  }
  return context;
};

export function ConfirmedParamsProvider(props: PropsWithChildren) {
  const [confirmedParams, setConfirmedParams] = useState<Params>({
    ...RESET_PARAMS,
  });
  const {children} = props;
  const contextValue = useMemo(
    () => ({confirmedParams, setConfirmedParams}),
    [confirmedParams, setConfirmedParams],
  );

  return (
    <ConfirmedParamsContext.Provider value={contextValue}>
      {children}
    </ConfirmedParamsContext.Provider>
  );
}

export default ConfirmedParamsProvider;
