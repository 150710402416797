/* eslint-disable import/no-extraneous-dependencies */
/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

'use client';

import {createTheme} from '@mui/material/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/roboto/latin.css';

declare module '@mui/material/styles' {
  interface PaletteColor {
    superlight?: string;
  }

  interface SimplePaletteColorOptions {
    superlight?: string;
  }
  interface Palette {
    grays: Palette['primary'];
    superlightSuccess: Palette['primary'];
    superlightWarning: Palette['primary'];
    searchBarGray: Palette['primary'];
    hotpink: Palette['primary'];
  }
  interface PaletteOptions {
    grays?: PaletteOptions['primary'];
    superlightSuccess?: PaletteOptions['primary'];
    superlightWarning?: PaletteOptions['primary'];
    searchBarGray?: PaletteOptions['primary'];
    hotpink?: PaletteOptions['primary'];
  }
}
const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: 'Roboto, Arial, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 96,
      fontWeight: 'light',
    },
    h2: {
      fontSize: 60,
      fontWeight: 'light',
    },
    h3: {
      fontSize: 48,
      fontWeight: 'regular',
    },
    h4: {
      fontSize: 34,
      fontWeight: 'regular',
    },
    h5: {
      fontSize: 24,
      fontWeight: 'regular',
    },
    h6: {
      fontSize: 20,
      fontWeight: 'medium',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 'regular',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 'medium',
    },
    body1: {
      fontSize: 16,
      fontWeight: 'regular',
    },
    body2: {
      fontSize: 14,
      fontWeight: 'regular',
    },
    button: {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 'medium',
    },
    caption: {
      fontSize: 12,
      fontWeight: 'regular',
    },
    overline: {
      textTransform: 'uppercase',
      fontSize: 10,
      fontWeight: 'regular',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1443d6',
      light: '#4368de',
      dark: '#0e2e95',
    },
    secondary: {
      main: '#090d7b',
      light: '#3a3d95',
      dark: '#060956',
      superlight: '#ebebf4',
    },
    error: {
      main: '#eb335c',
      light: '#ef5b7c',
      dark: '#a42340',
    },
    warning: {
      main: '#e19629',
      light: '#e7ab53',
      dark: '#9d691c',
      superlight: '#fcf6ed',
    },
    success: {
      main: '#1e9971',
      light: '#4bad8d',
      dark: '#004f48',
      superlight: '#edf6f3',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#156b4f',
    },
    text: {
      primary: '#000c2a',
      secondary: '#4d5b7c',
      disabled: '#8b8b8b',
    },
    background: {
      default: '#f7f8fc',
      paper: '#ffffff',
    },
    divider: '#ccccd2',
    grays: {
      main: '#e3e3e3',
      light: '#f1f1f1',
      dark: '#081822',
    },
    superlightSuccess: {
      main: '#edf6f3',
    },
    superlightWarning: {
      main: '#FCF6ED',
    },
    searchBarGray: {
      main: '#465259',
    },
    hotpink: {
      main: '#EE1C69',
    },
  },
  components: {
    MuiAvatar: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiLink: {
      defaultProps: {
        color: '#1A0DAB',
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.variant === 'filled' && {
            color: '#fff',
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root, & .MuiInputBase-root': {
            fontSize: 14,
            fontWeight: 'regular',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root, & .MuiInputBase-root': {
            fontSize: 14,
            fontWeight: 'regular',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontSize: 14,
            fontWeight: 'regular',
          },
        },
      },
    },
  },
});

export default theme;
